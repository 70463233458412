// @ts-ignore
import ReactDOM from 'react-dom/client'
import { lazy, ReactNode } from 'react'
import { parseConfig } from './shared/settings'
import { ConfigSchema } from './schema/config.schema'
const RetailView = lazy(() => import('./views/retail/RetailView'))
const CardView = lazy(() => import('./views/card/CardView'))
const IdentityView = lazy(() => import('./views/identity/IdentityView'))
const InvestorView = lazy(() => import('./apps/investor/InvestorView'))
const InvestorDocumentsView = lazy(
  () => import('./views/investor-documents/InvestorDocumentsView')
)
const AgreementView = lazy(() => import('./views/agreement/AgreementView'))
const DemoView = lazy(() => import('./apps/demo/DemoView'))
const CorporateView = lazy(() => import('./views/corporate/CorporateView'))
const OAuthView = lazy(() => import('apps/oauth/OAuthView'))
const TreasuryView = lazy(() => import('apps/treasury/TreasuryView'))
const KYCRetryView = lazy(() => import('views/kyc-retry/KYCRetryView'))
const EsignatureView = lazy(() => import('views/esignature/EsignatureView'))
const AgreementApp = lazy(() => import('./apps/agreement/AgreementView'))
const XiggitApp = lazy(() => import('./apps/xiggit/XiggitView'))
const OnyxApp = lazy(() => import('./apps/onyx/OnyxView'))

const rootEl = document.getElementById('atomic-module-container')
const root = ReactDOM.createRoot(rootEl)
const buildTarget = process.env.REACT_APP_BUILD_TARGET as
  | 'investor'
  | 'demo'
  | 'treasury'
  | 'agreement'
  | 'xiggit'
  | 'onyx'
  | undefined

// OAuth Flow has been implemented initially to handle redirects inside webview.
// This doesn't work with native app sign-in (AT-1714).
// The <OAuthView /> let us handle redirects in browser for both native app sign-in and web site sign-in.
// But, it's backward incompatible change. Blacklist we have in host application containts `https://cdn.plaid.com`
// which we should remove to enable new OAuth flow implementation.
// For now, there is workaround – checking if `plaidLinkToken` was saved into localStorage.
// See AT-1714 for more details.
// Eventually we need to remove the old way to handle oauth, getting rid of restoring module from localStorage.

const searchParams = new URLSearchParams(window.location.search)
const isPlaidRedirect = Boolean(localStorage.getItem('plaidLinkToken'))
const shouldOpenOAuthView =
  searchParams.has('oauth_state_id') && !isPlaidRedirect

if (shouldOpenOAuthView) {
  root.render(<OAuthView />)
} else if (buildTarget === 'investor') {
  root.render(<InvestorView />)
} else if (buildTarget === 'demo') {
  root.render(<DemoView />)
} else if (buildTarget === 'treasury') {
  root.render(<TreasuryView />)
} else if (buildTarget === 'agreement') {
  root.render(<AgreementApp />)
} else if (buildTarget === 'xiggit') {
  root.render(<XiggitApp />)
} else if (buildTarget === 'onyx') {
  root.render(<OnyxApp />)
} else {
  const searchParams = new URLSearchParams(window.location.search)
  const config = parseConfig(searchParams)
  const view = ConfigSchema.required().shape.view.parse(
    config.view || config.module || 'retail'
  )
  const views: { [key in View]: ReactNode } = {
    retail: <RetailView />,
    card: <CardView />,
    identity: <IdentityView />,
    'investor-documents': <InvestorDocumentsView />,
    agreement: <AgreementView />,
    corporate: <CorporateView />,
    'kyc-retry': <KYCRetryView />,
    esignature: <EsignatureView />,
  }
  root.render(views[view])
}
