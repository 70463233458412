import clone from 'lodash/clone'
import defaultsDeep from 'lodash/defaultsDeep'
import { isIOSWebView, isReactNativeWebView } from './usePostMessageChannel'
import localStorageHelper from './localStorageHelper'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import { ConfigSchema } from 'schema/config.schema'
import { ZodError, ZodIssueCode } from 'zod'
import { format } from './useSettings'
import { Feature } from './useFeature'

export const DEFAULT_CONTRIBUTION_PLAN_AMOUNT = 150

// Default atomic api – https://sandbox.api.atomicvest.com
const mode: Mode = 'sandbox'
const cluster: Cluster = 'prod'
const defaultOptions = {
  mode,
  cluster,
  shouldCloseAfterOnboarding: true,
}

const DEPRECATED_FEATURES = [
  'dev-persona-fix-kyc',
  'dashboard-identity-banner',
  'enable-corp-dashboard',
  'allow-international',
  'enable-kyc-retry',
]

const SUPPORTED_LANGUAGES = ['en', 'pt']

const ENFORCED_FEATURES: Feature[] = [
  'disable-email-requests',
  'disable-copy-agreement-link',
  'enable-kyc-retry',
]

// We force Plaid to use redirect flow on mobile devices.
if (isReactNativeWebView() || isIOSWebView()) {
  ENFORCED_FEATURES.push('plaid-oauth-redirect-flow')
}

export function parseConfig(searchParams: URLSearchParams) {
  return JSON.parse(decodeURIComponent(searchParams.get('config') || '{}'))
}

export function readFromConfig(
  data: unknown,
  enforcedFeatures: Feature[] = []
): Settings {
  let config

  // Ignore validation errors until we notify all partners about the change
  try {
    config = ConfigSchema.parse(data)
  } catch (error) {
    if (error instanceof ZodError) {
      config = data as any
      error.issues
        .map((issue) => format(issue))
        .forEach((msg) => console.log(msg))
    } else {
      throw error
    }
  }

  if (!config.token) {
    throw new ZodError([
      {
        code: ZodIssueCode.invalid_type,
        path: ['token'],
        message: 'Token is required',
        expected: 'string',
        received: 'undefined',
      },
    ])
  }

  // We need origin to send messages to parent window.
  // It's required for module embedded with iframe.
  // For webview we don't need origin.
  if (!config.origin && !isReactNativeWebView()) {
    throw new ZodError([
      {
        code: ZodIssueCode.invalid_type,
        path: ['origin'],
        message: 'Origin is required for all clients using iframe',
        expected: 'string',
        received: 'undefined',
      },
    ])
  }

  const settings = defaultsDeep(clone(config), defaultOptions)
  settings.features = settings.features ?? []

  // Warn about deprecated features
  settings.features.forEach((feature: string) => {
    if (DEPRECATED_FEATURES.includes(feature)) {
      console.warn(`${feature} feature is deprecated`)
    }
  })

  // Enforce features
  enforcedFeatures.forEach((feature) => {
    if (!settings.features.includes(feature)) {
      settings.features.push(feature)
    }
  })

  // TODO: Remove when completely remove 'customCss' support
  if (settings.customCss) {
    settings.custom_css = settings.customCss
    delete settings.customCss
  }

  if (
    isEmpty(settings.wire_instructions) &&
    !settings?.excluded_onboarding_pages?.includes('page-wire-details')
  ) {
    settings?.excluded_onboarding_pages?.push('page-wire-details')
  }

  if (settings?.excluded_onboarding_pages?.includes('page-connect-account')) {
    settings?.excluded_onboarding_pages?.push('page-unable-to-link-account')
  }

  if (!settings.settings) {
    settings.settings = {}
  }

  if (!settings.settings.initial_investment_steps) {
    settings.settings.initial_investment_steps = [100, 250, 500]
  }

  if (!settings.settings.contribution_plan_amount) {
    settings.settings.contribution_plan_amount =
      DEFAULT_CONTRIBUTION_PLAN_AMOUNT
  }

  if (isObject(settings.token)) {
    if (!settings.token.session_token) {
      throw new Error('readFromConfig - token.session_token is required')
    }
    if (!settings.token.entity_type) {
      throw new Error('readFromConfig - token.entity_type is required')
    }
    if (!settings.token.entity_id) {
      throw new Error('readFromConfig - token.entity_id is required')
    }
    if (!settings.token.identifier) {
      throw new Error('readFromConfig - token.identifier is required')
    }
    if (
      settings.view === 'corporate' &&
      settings.token.entity_type !== 'corporate'
    ) {
      throw new Error(
        'readFromConfig - token.entity_type should be "corporate" when using session config as token and view="corporate"'
      )
    }
  }

  // Provide default language
  if (!settings.language) {
    settings.language = 'en'
  }

  // Provide language fallback
  if (!SUPPORTED_LANGUAGES.includes(settings.language)) {
    settings.language = 'en'
  }

  // Provide default view
  if (!settings.view) {
    settings.view = 'retail'
  }

  // Backward compatibility
  if (
    settings.view === 'retail' &&
    settings.features.includes('page-us-status')
  ) {
    settings.features = [
      ...new Set(
        settings.features
          .filter((id: string) => id !== 'page-us-status')
          .concat('page-citizenship', 'page-us-residence', 'page-tax-id')
      ),
    ]
  }

  // Backward compatibility
  if (
    settings.view === 'retail' &&
    settings.features.includes('page-personal-info')
  ) {
    settings.features = [
      ...new Set(settings.features.concat('page-legal-address')),
    ]
  }

  if (!settings.end_user_ip) {
    settings.end_user_ip = '127.0.0.1'
  }

  // Backward compatibility
  if (settings.features.includes('enable-corp-dashboard')) {
    settings.shouldCloseAfterOnboarding = false
  }

  // Backward compatibility
  if (settings.content) {
    if (settings.theme) {
      delete settings.content
    } else {
      settings.theme = settings.content
    }
  }

  if (!settings.account_types) {
    const disableGeneralInvestmentAccount = settings.features.includes(
      'disable-general-investment-account'
    )
    const allowTrading = settings.features.includes('allow-trading')
    const retirementFeature = settings.features.includes(
      'allow-retirement-account'
    )
    const allowHighYield = settings.features.includes(
      'allow-high-yield-cash-account'
    )
    settings.account_types = [
      ...(disableGeneralInvestmentAccount ? [] : ['INDIVIDUAL']),
      ...(allowHighYield ? ['HIGH_YIELD'] : []),
      ...(allowTrading ? ['TRADING'] : []),
      ...(retirementFeature ? ['IRA_TRADITIONAL', 'IRA_ROTH'] : []),
    ]
  }

  if (settings.account_types.length > 1) {
    settings.features = [
      ...new Set(settings.features.concat('disable-auto-skip')),
    ]
  }

  return settings
}

export function readFromEnv(): [Settings, ZodError | undefined, any] {
  const savedConfig = localStorageHelper.get('config')
  if (savedConfig) {
    localStorageHelper.clear('config')
    return [savedConfig, undefined, undefined]
  }
  const searchParams = new URLSearchParams(window.location.search)
  const parsedConfig = parseConfig(searchParams)
  try {
    const resolvedConfig = readFromConfig(parsedConfig, ENFORCED_FEATURES)
    return [resolvedConfig, undefined, parsedConfig]
  } catch (error) {
    if (error instanceof ZodError) {
      return [{} as unknown as any, error, parsedConfig]
    } else {
      throw error
    }
  }
}

const urlToAtomicEnv = new Map<string, AtomicEnv>([
  ['https://api.atomicvest.com', 'prod-live'],
  ['https://sandbox.api.atomicvest.com', 'prod-sandbox'],
  ['https://api-live.qa.atomicvest.com', 'qa-live'],
  ['https://api-sandbox.qa.atomicvest.com', 'qa-sandbox'],
  ['https://api-sandbox.staging.atomicvest.com', 'staging-sandbox'],
])

const modeClusterToAtomicEnv = new Map<string, AtomicEnv>([
  ['prod:production', 'prod-live'],
  ['prod:sandbox', 'prod-sandbox'],
  ['qa:api-live', 'qa-live'],
  ['qa:api-sandbox', 'qa-sandbox'],
  ['staging:api-sandbox', 'staging-sandbox'],
])

export function makeAtomicEnv(settings: Settings) {
  if (settings._override_api) {
    return urlToAtomicEnv.get(settings._override_api)
  } else {
    const cluster = settings.cluster
    const mode = settings.mode
    return modeClusterToAtomicEnv.get([cluster, mode].join(':'))
  }
}
